import { Component, input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { StringCollection } from '../../shared.model';

@Component({
  selector: 'app-print-error',
  standalone: true,
  templateUrl: './print-error.component.html',
  styleUrl: './print-error.component.scss',
})
export class PrintErrorComponent {

  readonly control = input.required<AbstractControl | null>();

  getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config: StringCollection = {
      confirmPassword: 'Confirm password is not matching',
      eitherSivOrLpo: 'SIV or LPO is required',
      email: 'Enter valid email address',
      invalidCreditCard: 'Is invalid credit card number',
      invalidEmailAddress: 'Invalid email address',
      invalidPassword: 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      maxlength: `Minimum ${validatorValue.requiredLength} characters are allowed`,
      minlength: `Minimum ${validatorValue.requiredLength} characters required`,
      min: `Minimum should be ${validatorValue.min}`,
      required: 'This field is required',
      requireMatch: 'The value should be picked from list',
      codeExists: validatorValue.message,
    };
    return config[validatorName] || validatorValue;
  }

  errorMessage() {
    const control = this.control();
    if (control?.touched) {
      for (let propertyName in control.errors) {
        return this.getValidatorErrorMessage(propertyName, control.errors[propertyName]);
      }
    }
    return null;
  }
}
