@for(config of configuration(); track $index) {
<button mat-raised-button [type]="config.type" [disabled]="config.showLoading || config.disabled"
  (click)="buttonClick($event, config)" [color]="config.buttonColor" class="button-container">
  @if(config.showLoading) {
  <mat-spinner diameter="18" role="status" aria-hidden="true"></mat-spinner>
  } @else if (config.icon) {
  <mat-icon>{{config.icon}}</mat-icon>
  }
  <span class="btn-label">{{config.title}}</span>
</button>
}
