import { Component, input } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { Button } from './button.model';

declare let gtag: Function;

@Component({
  selector: 'app-button',
  imports: [MaterialModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  readonly configuration = input.required<Button[]>();

  buttonClick(event: Event, config: Button) {
    gtag('event', 'click', { event_category: config.analyticValue });
    if (config.action) {
      config.action(event);
    }
  }
}
